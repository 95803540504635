import { EuiButton, EuiButtonEmpty } from "@elastic/eui";

import FirebaseAuthFlowDebug from "app/features/auth/components/FirebaseAuthFlowDebug";
import { LoginPanel } from "app/features/auth/components/Login";
import FirebaseUIDiv from "app/features/auth/components/FirebaseUIDiv";
import { FormattedMessage } from "react-intl";
import FirebaseAuthFlowFooter from "app/features/auth/components/FirebaseAuthFlowFooter";

const debugFirebaseAuthFlow = false;

const FirebaseAuthFlowRedirect = ({
  tenantName,
  redirectSeconds,
  redirectUrl,
  hasCancelledRedirect,
  onConfirmRedirect,
  onCancelRedirect,
}) => {
  // TODO this debug should get a better place probably

  const hasNotCancelledRedirect = !hasCancelledRedirect;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {debugFirebaseAuthFlow && (
        <div style={{ width: 250 }}>
          <FirebaseAuthFlowDebug />
        </div>
      )}
      <div
        style={{
          paddingRight: debugFirebaseAuthFlow ? 250 : 0,
          margin: "auto",
        }}
      >
        <LoginPanel
          content={
            <div style={{ width: "100%" }}>
              <FirebaseUIDiv />
              <div
                style={{
                  paddingTop: 40,
                  textAlign: "center",
                }}
              >
                {hasCancelledRedirect ? (
                  <FormattedMessage id="FirebaseAuthFlowRedirect.cancelledRedirect" />
                ) : (
                  <>
                    <FormattedMessage id="FirebaseAuthFlowRedirect.redirectToTenantLogin" />
                    <br />
                    <h5>{redirectSeconds}</h5>
                  </>
                )}
              </div>

              <div
                style={{
                  paddingTop: 40,
                  textAlign: "center",
                }}
              >
                <EuiButton fill size="m" onClick={onConfirmRedirect}>
                  <FormattedMessage id="FirebaseAuthFlowRedirect.redirectNow" />
                </EuiButton>
                {hasNotCancelledRedirect && (
                  <>
                    &nbsp;&nbsp;
                    <EuiButtonEmpty size="m" onClick={onCancelRedirect}>
                      <FormattedMessage id="actions.Cancel" />
                    </EuiButtonEmpty>
                  </>
                )}
              </div>
            </div>
          }
          footer={<FirebaseAuthFlowFooter tenantName={tenantName} redirectUrl={redirectUrl} />}
        />
      </div>
    </div>
  );
};

export default FirebaseAuthFlowRedirect;
