import { useCallback, useState } from "react";
import { EuiButton, EuiFieldPassword, EuiFieldText, EuiSpacer } from "@elastic/eui";
import { FormattedMessage } from "react-intl";
import { useFormattedMessage } from "app/utils/intl";

const logo = require("assets/img/youplan-logo.png");
const logoStyle = {
  width: "100%",
  paddingTop: 40,
  paddingLeft: 150,
  paddingRight: 150,
  paddingBottom: 30,
};

const loginDiv = {
  background: "#fff",
  border: "1px solid #ddd",
  borderRadius: "3px",
  width: 500,
  paddingLeft: 20,
  paddingRight: 20,
  height: "100%",
};

const loginArea = {
  padding: 40,
  paddingTop: 0,
};

const loginField = {
  position: "relative",
  marginTop: 5,
};

const enterKeyCode = 13;

export const useSubmitIfKeyDownIsEnterKey = (onSubmit) =>
  useCallback(
    (e) => {
      if (e.keyCode === enterKeyCode) {
        onSubmit();
      }
    },
    [onSubmit],
  );

export const useChangeEUIField = (onChange) =>
  useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

export const LoginPanel = ({ content, footer }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      margin: "auto",
    }}
  >
    <div
      className="login-form"
      style={{
        height: 600,
        width: 500,
      }}
    >
      <div style={loginDiv}>
        <img alt="YouPlan" src={logo} style={logoStyle} />
        <div style={loginArea}>{content}</div>
      </div>
    </div>
    <div
      style={{
        margin: "auto",
        marginTop: 20,
        width: 500,
        paddingTop: 20,
        paddingLeft: 20,
        color: "#777",
        fontSize: 11,
      }}
    >
      {footer}
    </div>
  </div>
);

const LoginFooter = () => (
  <div>
    <FormattedMessage id="LoginFooter.regularLogin" />
    <br />
    <FormattedMessage id="LoginFooter.visitTenantLogin" />
    &nbsp;<i>/tenant/login</i>
  </div>
);

const Login = ({ onSubmit, isLoading }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailedMsg, setLoginFailedMsg] = useState("");
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidUsername, setIsInvalidUsername] = useState(false);

  // TODO
  //  These don't work properly when username+password are auto-filled by browser.
  const isEmptyUsername = !username || username === "";
  const isEmptyPassword = !password || password === "";
  const shouldDisableSubmit = isLoading; // || isEmptyPassword || isEmptyUsername;

  const handleChangeUser = useChangeEUIField(setUsername);
  const handleChangePassword = useChangeEUIField(setPassword);
  const handleSubmit = () => {
    onSubmit(username, password).catch((error) => {
      const { errorInfo } = error;
      if (errorInfo.status === 400) {
        const errorBody = errorInfo.response.body;

        // TODO we should not even allow to submit empty password...
        if (errorBody.password) {
          setLoginFailedMsg(errorBody[Object.keys(errorBody)[0]]);
          setIsInvalidPassword(true);
          setIsInvalidUsername(false);
          return;
        }

        setLoginFailedMsg(errorBody.non_field_errors);
        setIsInvalidPassword(true);
        setIsInvalidUsername(true);
      }
    });
  };

  // Manual check if enter is pressed. The form tag causes to much issues in firefox
  const submitIfKeyDownIsEnterKey = useSubmitIfKeyDownIsEnterKey(handleSubmit);

  const submitButton = (
    <EuiButton
      type={"submit"}
      fill={true}
      fullWidth={true}
      onClick={handleSubmit}
      isLoading={isLoading}
      isDisabled={shouldDisableSubmit}
    >
      {isLoading ? <FormattedMessage id="Login.loggingIn" /> : <FormattedMessage id="Login.logIn" />}
    </EuiButton>
  );

  return (
    <LoginPanel
      content={
        <div
          style={{
            paddingTop: 30,
            width: 300,
            margin: "auto",
          }}
        >
          <h5>Sign in</h5>
          <div style={loginField}>
            <EuiFieldText
              onKeyDown={submitIfKeyDownIsEnterKey}
              isInvalid={isInvalidUsername}
              placeholder={useFormattedMessage({ id: "Login.placeholder.username" })}
              name="username"
              icon="user"
              type="text"
              value={username}
              onChange={handleChangeUser}
            />
          </div>
          <div style={loginField}>
            <EuiFieldPassword
              onKeyDown={submitIfKeyDownIsEnterKey}
              isInvalid={isInvalidPassword}
              style={{ borderColor: "red" }}
              type="dual"
              autoComplete="current-password"
              placeholder={useFormattedMessage({ id: "Login.placeholder.password" })}
              name="password"
              value={password}
              onChange={handleChangePassword}
            />
          </div>
          <p style={{ color: "red" }}>{loginFailedMsg || <span>&nbsp;&nbsp;</span>}</p>
          <EuiSpacer size="m" />
          {submitButton}
        </div>
      }
      footer={<LoginFooter />}
    />
  );
};

export default Login;
