import { Component, forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import DateTime from "react-datetime";
import classNames from "classnames";
import Im from "immutable";
import PropTypes from "prop-types";

import moment, { dateFormat, timeFormat, datetimeFormat } from "app/utils/momentLocalized";
import { CustomIcon, Icons, emptyFunc, emptyList, nullFunc, trueFunc } from "app/utils/constants";

import ErrorList from "./ErrorList";
import Label from "./Label";
import HelpBlock from "./HelpBlock";
import { useFormatMessage } from "app/utils/intl";

//
// Field component
//
// A DateTimeDualField is a single text field split into two editors: date and time.
// The field value is always an ISO date string (with time zone information?) YYYY-MM-DDTHH:MMTTT.
// The editors keep their own typing state until the date is valid.
// There is no way to persist an unfinished/invalid date or time string.
//

// Spec:

// * component mounts with valid `props.value`:
// ** state for date and time editors is initialized with respective values

// * `props.value` changes
// ** state is cleared.
// ** further, same as initial mount case.

// * component mounts with invalid `props.value`:
// ** editor state is blank.

// * editors are modified. concatenated value string, is invalid timestamp.
// ** setState, no callback to outside

// * editors are modified. concatenated value string is valid timestamp.
// ** onChange callback is called with value string.
// ** further, same as initial mount case.

const defaultLocale = "nl";
const allowedDateFormats = [dateFormat];
const allowedTimeFormats = [timeFormat, "HHmm"];

const typeFormat = {
  undefined,
  time: timeFormat,
  date: dateFormat,
};

const dateInputStyle = {
  width: 100,
  // NOTE this fixes zIndex bugs with other form fields!
  zIndex: "auto",
};
const timeInputStyle = {
  width: 60,
  // NOTE this fixes zIndex bugs with other form fields!
  zIndex: "auto",
};
const inputGroupAddOnStyle = {
  width: 30,
  paddingLeft: 0,
  paddingRight: 0,
  textAlign: "center",
  cursor: "pointer",
  color: "inherit",
};
const innerDivStyle = {
  marginLeft: 0,
  marginRight: 0,
  display: "inline-flex",
};

const dateTimeTimeConstraints = { minutes: { step: 5 } };

/***
 * Parses single string value into {timestamp:Moment, dateString, timeString)
 * @param timestampString {string}
 * @param type {string}
 * @returns {{dateString: {string}, thisMoment: Moment, timeString: {string}}}
 */
function parseTimestampString(timestampString, type) {
  // Moment value of entire field or null if the combination dateString+timeString is not valid.
  let thisMoment = moment(timestampString, typeFormat[type]);
  // String values of date and time textboxes.
  // Not valid while user is typing them, state is kept in component.
  let dateString;
  let timeString;
  if (thisMoment.isValid()) {
    // Localized date part
    dateString = thisMoment.format(dateFormat);
    // Localized time part
    timeString = thisMoment.format(timeFormat);
  } else {
    thisMoment = null;
    dateString = "";
    timeString = "";
  }

  return {
    thisMoment,
    dateString,
    timeString,
  };
}

const CustomDatePickerInput = ({ openCalendar, ...inputProps }) => (
  <div className="input-group">
    <div className="input-group-addon" onClick={openCalendar} style={inputGroupAddOnStyle}>
      <i className="fa fa-calendar" />
    </div>
    <input {...inputProps} />
  </div>
);

const CustomDatePickerWeekNrTable = ({ viewDate: date }) => {
  if (!date) return null;

  // There are 6 weeks/rows, starting with the last week of the previous month
  const nrOfWeeks = 6;

  let startDate = date.clone().subtract(1, "months");
  startDate.date(startDate.daysInMonth()).startOf("week");

  const endDate = startDate.clone().add(nrOfWeeks, "weeks");

  const weekNrs = [];
  while (startDate.isBefore(endDate)) {
    weekNrs.push(startDate.week());
    startDate.add(1, "weeks");
  }

  return (
    <table>
      <thead>
        <tr>
          <th
            className="rdtSwitch"
            style={{
              backgroundColor: "#fff",
              cursor: "default",
            }}
          />
        </tr>
        <tr>
          <th />
        </tr>
      </thead>
      <tbody>
        {weekNrs.map((weekNr) => (
          <tr key={weekNr}>
            <td
              style={{
                color: "#222",
                backgroundColor: "#f4f4f4",
                borderRight: "1px solid #ddd",
                fontSize: 11,
                cursor: "default",
              }}
            >
              {weekNr}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const CustomDatePicker = forwardRef(
  (
    {
      value,
      onChange,
      closeOnSelect,
      isValidDate,
      locale = undefined,
      open = undefined,
      inputProps = {},
      showInput = true,
      showToday = false,
    },
    ref,
  ) => {
    // TODO
    //  when used standalone (not with DateTimeDualField) the onChange method should check whether a valid date has
    //  been entered in the input, currently the value passed to onChange is either string (invalid moment) or a moment...

    const formatMessage = useFormatMessage();

    const initialValueRef = useRef(value);
    const datetimeRef = useRef(null);

    const goToToday = useCallback(() => {
      const dt = datetimeRef.current;
      dt.setViewDate(moment());
      dt.navigate("days");
    }, []);

    useImperativeHandle(ref, () => {
      return {
        goToToday() {
          goToToday();
        },
        openCalendar() {
          datetimeRef.current.openCalendar();
        },
      };
    }, [goToToday]);

    const renderInput = useCallback(
      (props, openCalendar) => <CustomDatePickerInput openCalendar={openCalendar} {...props} />,
      [],
    );
    const renderView = useCallback(
      (mode, renderDefault) => {
        // Only for years, months and days view
        if (mode === "time") return renderDefault();

        const showWeekNrTable = mode === "days";
        const clickOnToday = (e) => {
          e.preventDefault();
          goToToday();
        };

        // We need to do these || checks because the weekDays might be initialized/rendered before the value has been set
        // this provided an educated guess to what view we will land on. TODO would be nice to handle this better!
        const viewDate = datetimeRef.current?.state.viewDate?.clone() || initialValueRef.current?.clone() || moment();
        const todayDiv = (
          <div>
            <button onClick={clickOnToday}>Today</button>
          </div>
        );
        const weekNrDiv = (
          <div
            className="weekNrs"
            style={{
              width: 30,
            }}
          >
            {showWeekNrTable && <CustomDatePickerWeekNrTable viewDate={viewDate} />}
          </div>
        );

        return (
          <div className="wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {showWeekNrTable && weekNrDiv}
              <div className="calendar" style={{ flexGrow: 1 }}>
                {renderDefault()}
              </div>
            </div>
            {showToday && todayDiv}
          </div>
        );
      },
      [goToToday],
    );

    inputProps = showInput
      ? {
          ...inputProps,
          style: dateInputStyle,
          placeholder: formatMessage({ id: "DateTimeDualField.date" }),
        }
      : undefined;
    isValidDate = isValidDate || trueFunc;

    return (
      <DateTime
        ref={datetimeRef}
        value={value}
        onChange={onChange}
        initialViewMode={"days"}
        isValidDate={isValidDate}
        open={open}
        closeOnSelect={closeOnSelect}
        dateFormat={dateFormat}
        locale={locale}
        timeFormat={null}
        renderInput={showInput ? renderInput : nullFunc}
        renderView={renderView}
        inputProps={inputProps}
      />
    );
  },
);

const CustomTimePickerInput = ({ openCalendar, ...inputProps }) => (
  <div className="input-group">
    <span className="input-group-addon" onClick={openCalendar} style={inputGroupAddOnStyle}>
      <i className="fa fa-clock-o" />
    </span>
    <input {...inputProps} />
  </div>
);

const CustomTimePicker = ({ value, closeOnSelect, inputProps = {}, locale, onChange, open = undefined }) => {
  const formatMessage = useFormatMessage();

  inputProps = {
    ...inputProps,
    style: timeInputStyle,
    placeholder: formatMessage({ id: "DateTimeDualField.time" }),
  };

  return (
    <DateTime
      closeOnSelect={closeOnSelect}
      value={value}
      dateFormat={null}
      locale={locale}
      timeConstraints={dateTimeTimeConstraints}
      renderInput={(props, openCalendar) => <CustomTimePickerInput openCalendar={openCalendar} {...props} />}
      inputProps={inputProps}
      onChange={onChange}
      open={open}
    />
  );
};

const isEmptyStringValue = (value) => !value || value === "";

const clearStyle = {
  cursor: "pointer",
  margin: "auto",
};

class DateTimeDualField extends Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value, type } = nextProps;
    if (prevState === null || value !== prevState.value) {
      return Object.assign({ value }, parseTimestampString(value, type));
    }

    return null;
  }

  handleChangeDate = (value) => {
    // Value can be:
    // - a moment, for a valid date
    // - a string, for anything else
    // Thanks, but we always want a string, valid or not.
    let dateString = value;
    let setStateCallback;

    const dateMoment = moment(value, allowedDateFormats, true);
    if (dateMoment.isValid()) {
      dateString = dateMoment.format(dateFormat);
      setStateCallback = () => this.handleChange({ dateString });
    } else if (isEmptyStringValue(dateString) && isEmptyStringValue(this.state.timeString)) {
      setStateCallback = this.handleClear;
    }

    if (this.state.dateString !== dateString) {
      this.setState({ dateString }, setStateCallback);
    }
  };

  handleChangeTime = (value) => {
    // Value can be:
    // - a moment, for a valid time
    // - a string, for anything else
    // Thanks, but we always want a string, valid or not.
    let timeString = value;
    let setStateCallback;

    const timeMoment = moment(value, allowedTimeFormats, true);
    if (timeMoment.isValid()) {
      timeString = timeMoment.format(timeFormat);
      setStateCallback = () => this.handleChange({ timeString });
    } else if (isEmptyStringValue(timeString) && isEmptyStringValue(this.state.dateString)) {
      setStateCallback = this.handleClear;
    }

    if (this.state.timeString !== timeString) {
      this.setState({ timeString }, setStateCallback);
    }
  };

  handleChange = ({ dateString, timeString }) => {
    const { type, onChange, onFieldChange, name } = this.props;

    let newValueString = null;
    if (dateString || timeString) {
      const newDateString = dateString || this.state.dateString;
      const newTimeString = timeString || this.state.timeString;

      let newValue;
      if (type === "time") {
        newValue = moment(newTimeString, timeFormat);
      } else {
        newValue = moment(`${newDateString} ${newTimeString}`, datetimeFormat);
      }

      // Only fire callback on valid datetimes
      if (newValue.isValid()) {
        newValueString = newValue.format(typeFormat[type]);
      }
    }

    if (onChange) onChange({ [name]: newValueString });
    if (onFieldChange) onFieldChange(newValueString);
  };

  handleClear = () => this.handleChange({});

  render() {
    const { errors, name, label, help, type, closeOnSelect, isValidDate, disabled, clearable } = this.props;
    const { thisMoment } = this.state;
    const inputName = `${name}_input`;

    const locale = this.context.locale || defaultLocale;
    const showDate = !type || type === "date";
    const showTime = !type || type === "time";

    // TODO
    //   - separate field logic and presentation

    const inputProps = {
      readOnly: disabled,
      className: "form-control",
    };

    const datePart = showDate && (
      <CustomDatePicker
        value={thisMoment}
        onChange={this.handleChangeDate}
        locale={locale}
        closeOnSelect={closeOnSelect}
        inputProps={{
          ...inputProps,
          value: this.state.dateString,
        }}
        isValidDate={isValidDate}
        open={disabled ? false : undefined}
      />
    );
    const timePart = showTime && (
      <CustomTimePicker
        value={thisMoment}
        onChange={this.handleChangeTime}
        locale={locale}
        closeOnSelect={closeOnSelect}
        inputProps={{
          ...inputProps,
          value: this.state.timeString,
        }}
        open={disabled ? false : undefined}
      />
    );

    const clearIconLink = (
      <a style={clearStyle} onClick={this.handleClear}>
        <CustomIcon icon={Icons.clear} />
      </a>
    );
    const clearPart = clearable && (thisMoment ? <>&nbsp;{clearIconLink}</> : <>&nbsp;&nbsp;&nbsp;</>);

    const inputElement = (
      <div className="DateTimeDualField__input">
        <div style={innerDivStyle}>
          {datePart}
          {timePart}
          {clearPart}
        </div>
      </div>
    );

    let labelElement = null;
    if (label !== null) {
      const LabelComponent = this.props.labelComponent || Label;
      labelElement = <LabelComponent inputName={inputName} label={label} />;
    }

    const ErrorListComponent = this.props.errorListComponent || ErrorList;

    return (
      <div className={classNames(this.props.classNames, { "has-error": errors.count() })}>
        {labelElement}
        {inputElement}
        <HelpBlock text={help} />
        <ErrorListComponent errors={errors} />
      </div>
    );
  }
}

DateTimeDualField.propTypes = {
  classNames: PropTypes.array,

  errors: PropTypes.instanceOf(Im.List),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  // value is an ISO timestamp
  // If it is a valid timestamp, the corresponding parts will be
  // extracted and editable with the date and time field.
  // If the value is not parsable, it is ignored and considered blank.
  value: PropTypes.string, // TODO value is string OR moment
  type: PropTypes.string,

  errorListComponent: PropTypes.func,
  labelComponent: PropTypes.func,

  // handlers
  onChange: PropTypes.func,
  onFieldChange: PropTypes.func,
  closeOnSelect: PropTypes.bool,
};
DateTimeDualField.defaultProps = {
  onChange: emptyFunc,
  onFieldChange: emptyFunc,
  closeOnSelect: false,

  classNames: [],

  errors: emptyList,
  label: null,
  value: "",
};
DateTimeDualField.contextTypes = {
  // moment locale
  // should be added to context by top app container
  locale: PropTypes.string,
};

export default DateTimeDualField;
