import combineImmutableReducers from "app/utils/redux/combineImmutableReducers";
import { actionTypes as userActionTypes } from "app/features/users/actions";

import appMessagesReducer from "app/features/appMessages/reducer";
import blocksReducer from "app/features/blocks/reducer";
import calendarsReducer from "app/features/calendars/reducer";
import labelsReducer from "app/features/labels/reducer";
import locationsReducer from "app/features/locations/reducer";
import planToolsReducer from "app/features/planTools/reducer";
import planBoardReducer from "app/features/planBoard/reducer";
import recurrencesReducer from "app/features/recurrences/reducer";
import reportsReducer from "app/features/reports/reducer";
import requirementsReducer from "app/features/requirements/reducer";
import resourcesReducer from "app/features/resources/reducer";
import resourceStatesReducer from "app/features/resourceStates/reducer";
import skillsReducer from "app/features/skills/reducer";
import snapshotsReducer from "app/features/snapshots/reducer";
import stockReducer from "app/features/stock/reducer";
import storagesReducer from "app/features/storages/reducer";
import usersReducer from "app/features/users/reducer";
import workReducer from "app/features/work/reducer";

//
// Main reducer for app
//
const reducer = combineImmutableReducers({
  appMessages: appMessagesReducer,
  blocks: blocksReducer,
  calendars: calendarsReducer,
  labels: labelsReducer,
  locations: locationsReducer,
  planBoard: planBoardReducer,
  planTools: planToolsReducer,
  recurrences: recurrencesReducer,
  reports: reportsReducer,
  requirements: requirementsReducer,
  resources: resourcesReducer,
  resourceStates: resourceStatesReducer,
  skills: skillsReducer,
  snapshots: snapshotsReducer,
  stock: stockReducer,
  storages: storagesReducer,
  users: usersReducer,
  work: workReducer,
});

const isShouldClearAction = (action) =>
  action.type === userActionTypes.LOGOUT ||
  action.type === userActionTypes.CLEAR_USER ||
  // action payload indicates the user is not authenticated anymore.
  (action.status === "error" && action.payload?.errorInfo?.status === 401);
// TODO clear when switching tenants too?

const autoClearReducer = (state, action) => {
  // If we should clear we drop the whole state by setting it to undefined, this forces a new initialState.
  const stateOrCleared = isShouldClearAction(action) ? undefined : state;
  return reducer(stateOrCleared, action);
};

export default autoClearReducer;
